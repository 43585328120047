import { Carousel }      from "@mantine/carousel";
import Autoplay          from "embla-carousel-autoplay";
import React, { useRef } from "react";

import Icon      from "_components/atoms/icon";
import Image     from "_components/atoms/image";
import useStyles from "_styles/pages/auth";

function LoginBanner ( { from } ) {
	const { classes } = useStyles ( { from } );

	const autoplay = useRef ( Autoplay ( { delay: 4000 } ) );

	return (
		<div style = { {
			flex       : "1",
			background : "white"
		} }
		>
			<Carousel
				classNames = { {
					container : classes.carouselContainer,
					root      : classes.carouselRoot,
					viewport  : classes.carouselViewport,
					control   : classes.control
				} }
				draggable = { true }
				loop = { true }
				nextControlIcon = { (
					<Icon sameInBothTheme
						className = { classes.controlIcon }
						name = "carouselRight"
					/>
				) }
				plugins = { [ autoplay.current ] }
				previousControlIcon = { (
					<Icon sameInBothTheme
						className = { classes.controlIcon }
						name = "carouselLeft"
					/>
				) }
				withIndicators = { true }
			>
				<Carousel.Slide>
					<Image
						className = { classes.carouselImg }
						src = { from === "mobile" ? "/images/loginPopupBanners/mweb1.webp" : "/images/loginPopupBanners/1.webp" }
					/>
				</Carousel.Slide>

				<Carousel.Slide>
					<Image className = { classes.carouselImg }
						src = { from === "mobile" ? "/images/loginPopupBanners/mweb2.webp" : "/images/loginPopupBanners/2.webp" }
					/>
				</Carousel.Slide>

				<Carousel.Slide>
					<Image className = { classes.carouselImg }
						src = { from === "mobile" ? "/images/loginPopupBanners/mweb3.webp" : "/images/loginPopupBanners/3.webp" }
					/>
				</Carousel.Slide>

			</Carousel>
		</div>
	);
}
export default LoginBanner;
