import { NumberInput } from "@mantine/core";

import Icon      from "_components/atoms/icon";
import useStyles from "_styles/pages/auth";

const component = props => {
	const {
		inputLength,
		mobile,
		handleLoginChange,
		errorLogin
	} = props;

	const { classes } = useStyles ( );

	return (

		<NumberInput
			hideControls
			autoComplete = "off"
			classNames   = { { root: classes.inputRoot, input: classes.input, icon: classes.icon } }
			error        = { errorLogin ? true : false }
			id           = "mobile"
			leftSection  = { (
				<div className = { classes.phoneLeftIcon }>
					<Icon
						sameInBothTheme
						className = { classes.flagIndiaIcon }
						name      = "flagIndia"
					/>

					<div>+91</div>
				</div>
			) }
			leftSectionWidth = { 120 }
			onChange = { handleLoginChange }
			onInput  = { e => {
				e.target.value = Math.max ( 0, parseInt ( e.target.value ) ).toString ().slice ( 0, inputLength );
			} }
			placeholder = "Enter Phone Number"
			radius      = "lg"
			size        = "xl"
			type        = "number"
			value       = { mobile }
		/>
	);
};

export default component;
