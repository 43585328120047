import { Modal, PinInput, useMantineTheme } from "@mantine/core";
import { GoogleLogin }                      from "@react-oauth/google";
import { useTranslation }                   from "react-i18next";

import { PrimaryButton }             from "_components/atoms/button";
import Icon                          from "_components/atoms/icon";
import LoginInput                    from "_components/atoms/loginInput";
import Render                        from "_components/atoms/render";
import Typography                    from "_components/atoms/typography";
import LoginBanner                   from "_components/pages/auth/loginBanner";
import { useGetCanWatchMoreContent } from "_jotai/compulsoryLogin";
import useStyles                     from "_styles/pages/auth";

const component = props => {
	const {
		otpLength,
		mobileLength,
		state,
		handleCloseLogin,
		submitOtpLogin,
		submitForOtp,
		handleNumberChange,
		handleOtpChange,
		loginPopupStatus,
		goBack,
		handleKeyDown,
		handleGoogleLogin,
		isActivatePage
	} = props;

	const {
		step,
		isSendingOtp,
		isResendOtp,
		count,
		otp,
		mobile
	} = state;

	const { classes }         = useStyles ( { isActivatePage } );
	const canWatchMoreContent = useGetCanWatchMoreContent ();
	const theme               = useMantineTheme ();

	{ /* const enableSendOtp = /^\d{10}$/.test ( mobile ); */ }
	const isOtpValid = /^\d{6}$/.test ( otp );

	const redirectTo = href => {
		var a = document.createElement ( "a" );

		a.href = href;
		a.setAttribute ( "target", "_blank" );
		a.click ();
	};

	if ( isActivatePage ) {
		return (
			<LoginFormContent
				count              = { count }
				handleGoogleLogin  = { handleGoogleLogin }
				handleNumberChange = { handleNumberChange }
				handleOtpChange    = { handleOtpChange }
				isActivatePage     = { isActivatePage }
				isOtpValid         = { isOtpValid }
				isResendOtp        = { isResendOtp }
				isSendingOtp       = { isSendingOtp }
				mobile             = { mobile }
				mobileLength       = { mobileLength }
				otp                = { otp }
				otpLength          = { otpLength }
				redirectTo         = { redirectTo }
				state              = { state }
				step               = { step }
				submitForOtp       = { submitForOtp }
				submitOtpLogin     = { submitOtpLogin }
			/>
		);
	}

	return (
		<Modal
			centered
			onClose         = { handleCloseLogin }
			onKeyDown       = { e => {
				handleKeyDown ( e, step );
			} }
			opened          = { loginPopupStatus }
			radius          = "lg"
			styles          = { {
				content: {
					flexBasis       : "min( 80vw, 700px ) !important",
					backgroundColor : theme.other.colors.background.primary
				},
				header: {
					padding: "0"
				},
				title: {
					width: "100%"
				},
				body: {
					padding: "0"
				},
				inner: {
					zIndex: "1400"
				},
				overlay: {
					zIndex: "1300"
				}
			} }
			withCloseButton = { false }
		>
			<div className = { classes.popupWrapper }>
				<LoginBanner />

				<div className = { classes.dialogContent }>
					<div className = { classes.dialogHeader }>
						<div className = { classes.titleContainet }
							id            = { "login-modal-popup" }
						>
							{step === 2 ? (
								<Icon
									className = { `${ classes.backArrow } cursorPointer` }
									name      = "backArrow"
									onClick   = { () => goBack ( 1 ) }
								/>
							) : (
								<Render condition = { canWatchMoreContent }>
									<Icon
										className = { classes.headerTitleIcon }
										name      = "rooterInfiniteLogo"
									/>
								</Render>
							)}

							<Typography
								className = { classes.title }
								size      = { "lg" }
								title     = { step === 2 ?
									"OTP Verification" :
									!canWatchMoreContent ?
										"Login to continue watching" :
										"Log in / Sign Up" }
								weight    = { 500 }
							/>
						</div>

						<Icon
							className = { classes.closeIcon }
							name      = "cross"
							onClick   = { handleCloseLogin }
						/>
					</div>

					<LoginFormContent
						count              = { count }
						handleGoogleLogin  = { handleGoogleLogin }
						handleNumberChange = { handleNumberChange }
						handleOtpChange    = { handleOtpChange }
						isActivatePage     = { isActivatePage }
						isOtpValid         = { isOtpValid }
						isResendOtp        = { isResendOtp }
						isSendingOtp       = { isSendingOtp }
						mobile             = { mobile }
						mobileLength       = { mobileLength }
						otp                = { otp }
						otpLength          = { otpLength }
						redirectTo         = { redirectTo }
						state              = { state }
						step               = { step }
						submitForOtp       = { submitForOtp }
						submitOtpLogin     = { submitOtpLogin }
					/>
				</div>
			</div>
		</Modal>
	);
};

export default component;

const LoginFormContent = ( {
	step,
	state,
	handleNumberChange,
	mobileLength,
	mobile,
	handleGoogleLogin,
	isSendingOtp,
	submitForOtp,
	handleOtpChange,
	otp,
	otpLength,
	isOtpValid,
	submitOtpLogin,
	isResendOtp,
	count,
	isActivatePage,
	redirectTo
} ) => {
	const { classes } = useStyles ();
	const { t }       = useTranslation ();

	return (
		<div className = { classes.formContent }>
			{
				step === 1 && (
					<div className = { classes.mainContent }>
						<Typography
							className = { classes.enterNoText }
							color     = { "secondary" }
							title     = "Enter your mobile number to continue:"
						/>

						<LoginInput
							errorLogin        = { state.mobileNumberError }
							errorMsgLogin     = { state.mobileNumberError }
							handleLoginChange = { handleNumberChange }
							inputLength       = { mobileLength }
							mobile            = { mobile }
						/>

						<PrimaryButton
							fullWidth
							dataTestid = "send-otp"
							disabled   = { state.mobile.toString ().length !== mobileLength }
							loading    = { isSendingOtp }
							onClick    = { submitForOtp }
							size       = { "sm" }
							title      = { t ( "continue" ) }
						/>

						<div className = { classes.alternateContainer }>
							<div className = { classes.leftDash } />

							<Typography
								className = { classes.orText }
								title     = "OR"
							/>

							<div className = { classes.rightDash } />

						</div>

						<div className = { classes.alternateContainer }
							data-testid   = "google-login-button"
						>

							<GoogleLogin
								logo_alignment = "left"
								onError        = { e => {
									throw e;
								} }
								onSuccess      = { handleGoogleLogin }
								shape          = "pill"
								theme          = "filled_blue"
								width          = "100px"
							/>
						</div>

						{
							isActivatePage ?
								null : (
									<div className = { classes.policyContainer }>
										<p className = { classes.policyText }
											onClick     = { () => {
												redirectTo ( "https://terms-policy.rooter.io/" );
											} }
										>
											{t ( "nav_menu_tos" )}
										</p>

										<span className = { classes.ellipse }></span>

										<p className = { classes.policyText }
											onClick     = { () => {
												redirectTo ( "https://privacy-policy.rooter.io/" );
											} }
										>
											{t ( "nav_menu_policy" )}
										</p>
									</div>
								)
						}
					</div>
				)
			}

			{
				step === 2 && (
					<div className = { `${ classes.otpMainContent } textCenter` }>
						<div className = { classes.marginBottom }>
							<Typography
								title = { "A 6 digit OTP has been sent to " }
							/>

							<Typography
								title = { `+91 ${ mobile }` }
							/>

							<Typography
								title = { "Enter it below to continue" }
							/>
						</div>

						<PinInput
							autoFocus
							classNames  = { { root: classes.pinRoot, input: classes.pinInput, error: classes.pinError } }
							error       = { Boolean ( state.otpError && state?.otpErrorMsg || "" ) }
							id          = "otp"
							inputProps  = { { "data-testid": "enter-otp" } }
							length      = { otpLength }
							onChange    = { handleOtpChange }
							placeholder = ""
							type        = "number"
							value       = { otp }
						/>

						<PrimaryButton
							fullWidth
							className  = { classes.mb2 }
							dataTestid = "verify-otp"
							disabled   = { !isOtpValid ? true : false }
							onClick    = { submitOtpLogin }
							title      = { t ( "login_verify_otp" ) }
						/>

						<Typography
							className = { `${ classes.resetOtpText } ${ isResendOtp && "cursorPointer" }` }
							onClick   = { e => {
								isResendOtp && submitForOtp ( e );
							} }
							title     = { !isResendOtp ? `Resend OTP in ${ count }` : "Resend OTP" }
						/>

					</div>
				)
			}

		</div>
	);
};
